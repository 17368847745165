<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9"></v-col>
        <v-col cols="3">
          <v-btn right color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Role</span>
          </v-btn>
        </v-col>
      </v-row>
      <role-add-new
        @refetch-data="fetchRoles"
        :BranchId="parseInt($router.currentRoute.params.id)"
        v-model="isAddActive"
      ></role-add-new>
      <role-delete @refetch-data="fetchRoles" :Id="roleTemp.Id" v-model="isDialogDelete" />
      <role-edit @refetch-data="fetchRoles" :Role="roleTemp" v-model="isDialogEdit" :RoleSelected="RoleSelected"
      :totalRoleSelected="totalRoleSelected"/>
      <!-- Main Card -->
      <app-card-actions @refresh="fetchRoles">
        <template slot="title"> Role List </template>

        <v-card-text>
          <v-data-table
            :headers="tableColumns"
            :items="RoleListTable"
            :options.sync="options"
            :server-items-length="totalRoleListTable"
          >
            <!-- Id -->
            <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

            <!-- Actions -->
            <template #[`item.Actions`]="{ item }">
              <div class="demo-space-x">
                <v-btn icon color="error" @click.stop=";(isDialogDelete = !isDialogDelete), (roleTemp = { ...item })">
                  <v-icon small>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
                <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (roleTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </app-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import RoleAddNew from './RoleAddNew.vue'
import RoleDelete from './RoleDelete.vue'
import RoleEdit from './RoleEdit.vue'
import useRoleList from './useRoleList'

export default {
  components: {
    RoleAddNew,
    RoleDelete,
    RoleEdit,
    AppCardActions,
  },
  setup() {
    const isAddActive = ref(false)
    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const roleTemp = ref({ Id: '' })

    const { RoleListTable, tableColumns, searchQuery, totalRoleListTable, options, CategoryTotalLocal, fetchRoles, RoleSelected,
      totalRoleSelected } =
      useRoleList()
    return {
      roleTemp,
      isAddActive,
      isDialogDelete,
      isDialogEdit,
      RoleListTable,
      tableColumns,
      searchQuery,
      totalRoleListTable,
      options,
      CategoryTotalLocal,
      fetchRoles,
      RoleSelected,
      totalRoleSelected,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
