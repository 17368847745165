<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Role : {{ Role.Id }}</span>
          <span class="headline">Ability: {{Role.Ability}}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="RoleData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <!-- <router-ability :selected="RoleData" :type="'Edit'" v-model="RoleData.RouterAbility" :RoleSelected="RoleSelected" /> -->
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="500">
   <!-- <v-row v-for="item in items " :key="item.subject">
   <v-col class="ml-5">
   <v-checkbox
      v-model="RoleSelected"
      :label="item.name"
      value="John"
    ></v-checkbox>
    </v-col>
    </v-row> -->

    <v-treeview
      v-model="RoleData.RouterAbility"
      :items="items"
      selection-type="leaf"
      selectable
      return-object
      open-all
      :item-key="items.name"
      @input="SetRouterAbility"
    >
     <template v-slot:prepend="{ item, selected }">
      <p v-bind:se="se.includes(item.name) ? selected=true : false" >
       
        <v-chip
        v-if="selected"
           class="ma-2"
           color="success"
           text-color="secondary"
         ><v-avatar left>
            <v-icon color="success">{{icons.mdiCheckboxMarkedCircle}}</v-icon>
      </v-avatar>
      
    </v-chip>
         <v-chip
         v-if="!selected"
         class="ma-2"
         text-color="secondary"
         color="error"
         ><v-avatar left>
            <v-icon color="error">{{icons.mdiCloseCircle }}</v-icon>
      </v-avatar>
      
    </v-chip>
      </p>
    </template>
    </v-treeview>
  </v-sheet>
       </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import routes from '@/router/index'
import { Edit } from '@core/api/Role'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
//import RouterAbility from './RouterAbility.vue'
export default {
 // components: { RouterAbility },
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Role: { type: Object, required: true },
    RoleSelected: {
      type: Array,
    },
    totalRoleSelected: {
      type: Number,
    },
  },
  watch: {
    Role(New) {
      this.RoleData = New
      this.se = JSON.parse(this.RoleData.Ability).reduce((filtered, option) => {
        console.log('filtered',filtered) 
        console.log('option',option)
             var someNewValue = option.subject
             filtered.push(someNewValue)
          
       // console.log('filtered', filtered)
        return filtered
      }, [])
      console.log('sesesesese.value', this.se)
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      Name: '',
      Ability: '',
    }
 const se = ref([1,2])
    const selection = ref([])
    const items = ref([])
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    // const resetForm = () => {
    //   form.value.reset()
    // }

    const RoleData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetRoleData = () => {
      
      RoleData.value = JSON.parse(JSON.stringify(blankData))
      form.value = []
      //resetForm()
      emit('update:is-dialog-edit', false)
    }
  onMounted(() => {
      
      items.value = routes.options.routes.reduce((filtered, option, index) => {
        if (option.meta) {
          if (option.meta.resource && option.meta.resource != 'Public') {
            var someNewValue = {
              id: index,
              name: option.name,
              value: { action: 'read', subject: option.meta.resource },
            }
            filtered.push(someNewValue)
          }
        }
       // console.log('filtered', filtered)
        return filtered
      }, [])
      
    })
    const SetRouterAbility = () => {
      RoleData.value = JSON.parse(JSON.stringify(RoleData.value.RouterAbility))
      // selection.value.map(m => {
      //     return m.value
      //   })
    }
    const test = item=>{ se.value.includes(item)? true : false }
    
        const onSubmit = () => {
      if (valid.value) {
        RoleData.value.Ability = JSON.stringify(RoleData.value.RouterAbility)
        Edit(RoleData.value).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })

        resetRoleData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetRoleData()
    }
   

//     const change = () => {
//        se.value = props.Role
//        console.log('sesesesese.value', se.value)
//         }
// watch([ props.Role ], () => {
//     change()
//   },
// )
    return {
      test,
      se,
      selection,
      items,
      SetRouterAbility,
      resetRoleData,
      form,
      onSubmit,
      RoleData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
