import router from '@/router'
import { Get } from '@core/api/Role'
import { ref, watch } from '@vue/composition-api'

export default function useCategoryList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]
  const RSelected = ref([])
  const RoleSelected = ref(
  //  JSON.parse(RSelected)
    )
  const RoleListTable = ref([])
  const searchQuery = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalRoleListTable = ref(0)
  const totalRoleSelected = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const roleTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchRoles = () => {
    Get()
      .then(response => {
        // handle success
        RoleListTable.value = response
        totalRoleListTable.value = response.lenght
        roleTotalLocal.value = response.lenght

        response.Ability == '' ? (RSelected.value = []) : (RSelected.value = response.Ability)
        totalRoleSelected.value = RSelected.lenght
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    fetchRoles({ BranchId: BranchId.value })
  })
  return {
    RSelected,
    totalRoleSelected,
    RoleSelected,
    RoleListTable,
    tableColumns,
    searchQuery,
    BranchId,
    statusFilter,
    totalRoleListTable,
    options,
    roleTotalLocal,
    fetchRoles,
  }
}
