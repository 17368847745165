<template>
 <div>

  <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="500" v-if="type == 'Add'">
    <v-treeview
      v-model="selection"
      :items="items"
      selection-type="leaf"
      selectable
      return-object
      open-all
      @input="SetRouterAbility"
    ></v-treeview>
  </v-sheet>
   <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="500" v-if="type == 'Edit'">
   <!-- <v-row v-for="item in items " :key="item.subject">
   <v-col class="ml-5">
   <v-checkbox
      v-model="RoleSelected"
      :label="item.name"
      value="John"
    ></v-checkbox>
    </v-col>
    </v-row> -->

    <v-treeview
      v-model="se"
      :items="items"
      selection-type="leaf"
      selectable
      return-object
      open-all
      activatable
      :active.sync="se"
      @input="SetRouterAbility"
    >
     <template v-slot:append="{ item, selected, active }">
      <p v-bind:se="item.id == 1 ? selected=true : false" >
        {{ selected }} {{item.id}}
      </p>
    </template>
    </v-treeview>
  </v-sheet>
 </div>
</template>
<script>
import routes from '@/router/index'
import { onMounted, ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'RouterAbility',
    event: 'update:RouterAbility',
  },
  props: {
    selected: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
    },
    RoleSelected: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      console.log('RoleSelected', props.selected)
      items.value = routes.options.routes.reduce((filtered, option, index) => {
        if (option.meta) {
          if (option.meta.resource && option.meta.resource != 'Public') {
            var someNewValue = {
              id: index,
              name: option.name,
              value: { action: 'read', subject: option.meta.resource },
            }
            filtered.push(someNewValue)
          }
        }
       // console.log('filtered', filtered)
        return filtered
      }, [])
      
    })
    const SetRouterAbility = () => {
      emit(
        'update:RouterAbility',
        selection.value.map(m => {
          return m.value
        }),
      )
    }
    const se = ref([1,2])
    const selection = ref([])
    const items = ref([])
  //     watch(
  //   [
  //   selected
  //   ],
  //   () => {
  //    // fetchOrders()
  //   },
  // )
    return {
      se,
      selection,
      items,
      SetRouterAbility,
    }
  },
}
</script>