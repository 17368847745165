<template>
  <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="500">
    <v-checkbox
      v-for="(item, i) in items"
      :key="i"
      v-bind:label="'Can ' + item.action + ' ' + item.subject"
      color="red"
      @change="
        $event => {
          if ($event) selection.push({ action: item.action, subject: item.subject })
          else {
            selection.splice(
              selection.findIndex(f => f.action == item.action && f.subject == item.subject),
              1,
            )
          }
          SetActionAbility()
        }
      "
      :true-value="true"
      :false-value="false"
      hide-details
    ></v-checkbox>
  </v-sheet>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'ActionAbility',
    event: 'update:ActionAbility',
  },
  setup(props, { emit }) {
    const SetActionAbility = () => {
      emit('update:ActionAbility', selection.value)
    }
    const selection = ref([])
    const items = ref([
      { action: 'add', subject: 'Item' },
      { action: 'delete', subject: 'Item' },
      { action: 'edit', subject: 'Item' },
    ])
    return {
      selection,
      items,
      SetActionAbility,
    }
  },
}
</script>
