import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7","max-height":"500"}},_vm._l((_vm.items),function(item,i){return _c(VCheckbox,{key:i,attrs:{"label":'Can ' + item.action + ' ' + item.subject,"color":"red","true-value":true,"false-value":false,"hide-details":""},on:{"change":function ($event) {
        if ($event) { _vm.selection.push({ action: item.action, subject: item.subject }) }
        else {
          _vm.selection.splice(
            _vm.selection.findIndex(function (f) { return f.action == item.action && f.subject == item.subject; }),
            1
          )
        }
        _vm.SetActionAbility()
      }}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }