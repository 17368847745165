import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type == 'Add')?_c(VSheet,{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7","max-height":"500"}},[_c(VTreeview,{attrs:{"items":_vm.items,"selection-type":"leaf","selectable":"","return-object":"","open-all":""},on:{"input":_vm.SetRouterAbility},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1):_vm._e(),(_vm.type == 'Edit')?_c(VSheet,{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7","max-height":"500"}},[_c(VTreeview,{attrs:{"items":_vm.items,"selection-type":"leaf","selectable":"","return-object":"","open-all":"","activatable":"","active":_vm.se},on:{"update:active":function($event){_vm.se=$event},"input":_vm.SetRouterAbility},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
var active = ref.active;
return [_c('p',{attrs:{"se":item.id == 1 ? selected=true : false}},[_vm._v(" "+_vm._s(selected)+" "+_vm._s(item.id)+" ")])]}}],null,false,18268598),model:{value:(_vm.se),callback:function ($$v) {_vm.se=$$v},expression:"se"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }