import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"9"}}),_c(VCol,{attrs:{"cols":"3"}},[_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"right":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddActive = !_vm.isAddActive}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Role")])],1)],1)],1),_c('role-add-new',{attrs:{"BranchId":parseInt(_vm.$router.currentRoute.params.id)},on:{"refetch-data":_vm.fetchRoles},model:{value:(_vm.isAddActive),callback:function ($$v) {_vm.isAddActive=$$v},expression:"isAddActive"}}),_c('role-delete',{attrs:{"Id":_vm.roleTemp.Id},on:{"refetch-data":_vm.fetchRoles},model:{value:(_vm.isDialogDelete),callback:function ($$v) {_vm.isDialogDelete=$$v},expression:"isDialogDelete"}}),_c('role-edit',{attrs:{"Role":_vm.roleTemp,"RoleSelected":_vm.RoleSelected,"totalRoleSelected":_vm.totalRoleSelected},on:{"refetch-data":_vm.fetchRoles},model:{value:(_vm.isDialogEdit),callback:function ($$v) {_vm.isDialogEdit=$$v},expression:"isDialogEdit"}}),_c('app-card-actions',{on:{"refresh":_vm.fetchRoles}},[_c('template',{slot:"title"},[_vm._v(" Role List ")]),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.RoleListTable,"options":_vm.options,"server-items-length":_vm.totalRoleListTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.Id)+" ")]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"demo-space-x"},[_c(VBtn,{attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();;(_vm.isDialogDelete = !_vm.isDialogDelete), (_vm.roleTemp = Object.assign({}, item))}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();;(_vm.isDialogEdit = !_vm.isDialogEdit), (_vm.roleTemp = Object.assign({}, item))}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1)]}}],null,true)})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }